<template>
    <k-link :to="{ name: 'dictionary-detail', params: { id: technology } }">
        <span
            class="tw-uppercase tw-rounded-sm tw-font-mono tw-inline-block tw-leading-none tw-transition tw-duration-150"
            :class="{
                'tw-px-2 tw-py-1.5 tw-text-sm': size === 'base',
                'tw-px-3 tw-py-2 tw-text-base': size === 'lg',
                'tw-bg-gray-100 hover:tw-bg-gray-200 tw-text-gray-700': variant === 'light' && !userKnowTechnology,
                'tw-bg-gray-700 hover:tw-bg-gray-600 tw-text-white': variant === 'dark' && !userKnowTechnology,
                'tw-bg-green-alt hover:tw-bg-green-alt tw-text-green': variant === 'light' && userKnowTechnology,
                'tw-bg-green hover:tw-bg-green tw-text-green-alt': variant === 'dark' && userKnowTechnology
            }"
        >
            {{ technology }}
        </span>
    </k-link>
</template>

<script>
export default {
    props: {
        technology: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: "base",
            validator: value => ["base", "lg"].indexOf(value) !== -1
        },
        variant: {
            type: String,
            default: "light",
            validator: value => ["light", "dark"].indexOf(value) !== -1
        }
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        },
        userKnowTechnology() {
            if (this.profile && Array.isArray(this.profile.technologies)) {
                return !!this.profile.technologies.find(t => String(t).toLowerCase() === String(this.technology).toLowerCase());
            } else {
                return false;
            }
        }
    }
};
</script>
